/*
 * @Description:
 * @Author:
 * @Date: 2023-05-13 15:28:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-05-19 23:30:53
 * @FilePath: \advertising-h5\src\router\index.js
 */
// 配置路由
import Vue from 'vue'
import VueRouter from "vue-router";

import Home from '../pages/inHome'
import Detial from '../pages/inDetial'
import LoadingData from '../pages/LoadingData'
import noRights from '../pages/noRights'
import privacyPolicy from '../pages/privacyPolicy'
import userAgreement from '../pages/userAgreement'
import Play from '../pages/inPlay'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter);

// 路由配置
const router = new VueRouter({
    mode: 'history',
    //配置路由路径和路由组件
    routes:[
        {
            //游戏商城首页
            path:'/',
            name:'Free Online Games',
            component: Home,    
            meta: {
                isAuth: true,
                title: 'Free Online Games',
                isIndex: true
            }
        },
        {
            //游戏详情 
            path:'/detial.html',
            name:'Free Online Games',
            component: Detial,   
            meta: {
                isAuth: true,
                title: 'Free Online Games'
            }
        },
        {
            //游戏
            path:'/play.html',
            name:'Free Online Games',
            component: Play,
            meta: {
                isAuth: true,
                title: 'Free Online Games'
            }
        },
        {
            //渠道商访问获取访问权限  
            path:'/Loading',
            name:'LoadingData',
            component: LoadingData,  
            meta: {
                title: 'Loading'
            }
        },
        {
            //无权访问页面 403 
            path:'/403',
            name:'403',
            component: noRights,  
            meta: {
                title: '403'
            }
        },
        {
            //隐私政策页面 
            path:'/privacyPolicy',
            name:'privacyPolicy',
            component: privacyPolicy,  
            meta: {
                title: 'privacyPolicy'
            }
        },
        {
            //用户协议页面 
            path:'/userAgreement',
            name:'userAgreement',
            component: userAgreement,  
            meta: {
                title: 'userAgreement'
            }
        },
        // {
        //     //重定向-游戏商城获取权限访问页面（确定有权限才允许跳转首页）
        //     path:'/*',
        //     redirect: '/Loading',
        //     component: Home,
        // }
    ]
})


// 2023.09.01 权限控制不用拦截
// 全局路由守卫，校验是否有权访问
// router.beforeEach((to, from, next) => {
//     console.log('全局前置路由守卫',to, from);
//     if(to.meta.isAuth) {
//         // 需要校验权限
//         // 允许首页默认可访问 || 有访问游戏商城权限可访问
//         if(to.meta.isIndex || localStorage.getItem('isLookGameMall')) {
//             window.document.title = to.meta.title 
//             next();
//         } else {
//             window.document.title = to.meta.title 
//             next({path: '/403'})
//         }
//     } else {
//         window.document.title = to.meta.title 
//         next();
//     }
// })

export default router 
