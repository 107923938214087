<template>
    <div class="detial">
        <!-- 商城首页顶部标题 -->
        <component-pages-title :isShowMenu="isShowMenu"></component-pages-title>
<!--      <div class="gptslot" data-adunitid="0"></div>-->

        <!-- loading -->
      <div class="loading-box flex-center" v-if="isShowLading">
        <van-loading type="spinner" color="#fff" />
      </div>
      <!-- 详情页内容 -->
        <div class="d-body-box" v-cloak v-if="!isShowLading">
            <!-- 详情内容头部 -->
            <div class="d-title flex-start">
                <img :src="gameDetials.logo" alt="">
                <div class="d-msg">
                    <div class="d-name">
                         <span class="d-name-title">{{ gameDetials.enName }}</span>
                         <span class="is-new" v-if="gameDetials.isNew == '1'">NEW</span></div>
                    <div class="d-hot" v-if="gameDetials.isHot">★★★★★</div>
                </div>
            </div>

            <!-- loading 与 play -->
            <div class="d-play">
                <!-- loading -->
                <el-progress v-if="(time<100)" :text-inside="true" :stroke-width="40" :percentage="time" :text-color="textColors" status="warning"></el-progress>
                <!-- play 按钮 -->
                <el-row v-else>
                    <a :href="`${origin}/play.html?gameurl=${encodeURIComponent(gameDetials.url)}`">
                        <el-button type="warning" round >PLAY</el-button>
                    </a>
                </el-row>
            </div>
            <!-- 游戏详情描述 -->
            <div class="d-detail-message">
                <p v-text="gameDetials.remark"></p>
            </div>
          <div class="wrapper" id='div-gpt-ad-1401715229426' style='min-width: 300px; min-height: 250px;'></div>
            <!-- 详情页-广告位 Ads -->
<!--            <div class="d-ads">-->
<!--                &lt;!&ndash; <components-ads-new :divId="detailDivId" :adCode="detaiAdCode"></components-ads-new> &ndash;&gt;-->
<!--&lt;!&ndash;                <components-ads-detail></components-ads-detail>&ndash;&gt;-->
<!--&lt;!&ndash;              <div class="gptslot" data-adunitid="4"></div>&ndash;&gt;-->
<!--              <div class="gptslot" data-adunitid="3"></div>-->
<!--            </div>-->

            <!-- 详情页-底部推荐游戏列表 -->
            <component-more-game></component-more-game>

            <!-- 详情页-底部条款及说明部分 -->
            <component-conditions></component-conditions>

        </div>


    </div>
</template>
<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
<script>
    import { mapMutations, mapState } from "vuex";
    import { getGamesDetials, getPageGamesList, getQueryByChannel } from "@/api/index.js";
    import pagesTitle from "../components/component-pages-title.vue";
    import conditions from "../components/component-terms-and-conditions.vue";
    import moreGameList from "../components/component-detial-game-list.vue";
    import googleAds from "../components/component-google-ads.vue";
    import googleAdsDetail from "../components/component-ads-new.vue";
    export default {
        name: 'gameDetial',
        components: {
            "component-pages-title": pagesTitle,
            "component-conditions": conditions,
            "component-more-game": moreGameList,
          'components-ads-new': googleAds,
          "components-ads-detail": googleAdsDetail,
        },
        data() {
            return {
                gameDetials: null, //游戏详情
                time: 0,//计时器
                textColors: '#fff',
                isShowMenu: false,//头部是否展示菜单按钮
                //获取分页游戏数据入参
                getPageGamesListData: {
                    pageSize: 39,
                    pageNum: 1,
                    isEnable: 1,
                    isDelete: 0
                },
                moreGameList: [],//更多推荐游戏
                detialAdsData: [],
                detailDivId: '', // 详情页 广告 dom id
                detaiAdCode: '', // 详情 广告 id
                origin: window.location.origin,
            }
        },
        computed: {
            ...mapState(['channelAdsDateXQ']),
            isShowLading() {
                return this.gameDetials == null ? true : false;
            }
        },
        created() {

            // this.getAds()

            document.documentElement.scrollTop = 0;
            this.getGamesDetialsFn();
            this.getAllGameMenuFn();
            this.detialAdsData = JSON.parse(localStorage.getItem('ChannelAdsDateXQ'));

            let timer = setInterval(() => {
                if(this.time < 100){
                    this.time ++;
                } else {
                    clearInterval(timer);
                }
            }, 30);
        },
      watch: {
        isShowLading: {
          handler(val) {
            if (val) {
              this.$nextTick(() => {
                googletag.cmd.push(function() { googletag.display('div-gpt-ad-1401715229426'); });

              })
            }
          },
          immediate: true
        }
      },
        mounted() {

        },
        methods: {
            ...mapMutations(['updateDetialMoreGame']),

            // 获取菜单数据
            getAllGameMenuFn() {
                // 获取详情页，底部推荐游戏数据
                getPageGamesList(this.getPageGamesListData)
                    .then((res) => {
                        console.log("详情页===> 获取的分页数据 ===>");
                        console.log(res);

                        // 汇总分页游戏数据
                        if (res.rows && res.rows.length > 0) {
                            this.moreGameList = res.rows.sort(function (a, b) {
                                return a.order - b.order; // 游戏-按order 从小到大排序
                            });
                        }

                        this.updateDetialMoreGame(this.moreGameList);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            },


            // 获取当前游戏详情数据
            getGamesDetialsFn(gameid) {
                let params = {
                    id: gameid ? gameid : this.$route.query.id
                }
                getGamesDetials(params).then(res => {
                    console.log(res)
                    if(res.code == 200 && res.data) {
                        this.gameDetials = res.data;
                    }
                }).catch(err => {
                    console.log(err)
                })

            },
            // 跳转游戏界面
            playTheGame() {
                // location.href = this.gameDetials.url;
                location.href = `${location.origin}/play.html?gameurl=${this.gameDetials.url}`
            },
        },
    }
</script>

<style lang="scss" scoped>
.detial {
    padding: 30px 10px;
    .d-title {
        margin: 1.2rem auto 0.8rem;
        img {
            width: 130px;
            height: 130px;
            margin-right: 15px;
        }
        .d-msg {
            font-size: 20px;
            font-weight: 700;
            .d-hot {
                color: #ffe019;
            }
            .d-name {
                display: flex;
                align-items: center;
                justify-content: space-between;
                white-space: initial;
                .is-new {
                    margin-left: 5px;
                    font-size: 14px;
                    color: #f00;
                }
            }
        }
    }
    .d-play {
        margin: 30px auto;
    }
    .d-detail-message {
        padding: 15px 10px;
        background: rgba(0,0,0,.15);
        border-radius: 10px;
        font-size: 14px;
        p {
            width: 100%;
            white-space: normal;
        }
    }

}

// play按钮
.el-progress-bar .el-progress-bar__outer,
.el-button.el-button--warning.is-round {
    padding: 10px 30px;
    width: 100% !important;
    height: 1.067rem !important;
    font-size: 24px !important;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}
/* loading */
.loading-box {
    height: 100vh;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 99;
    transform: translate(-50%,-50%);
}
</style>