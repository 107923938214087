<template>
  <div id="app">
    <div class="page-main" ref="scrollWrap">
      <!-- 商城首页顶部标题 -->
      <component-pages-title :isShowMenu="isShowMenu" @resetGameDataFn="resetGameDataFn"></component-pages-title>

      <!-- 首页主体内容部分 -->
      <div class="page-body-content" ref="scrollCont">
<!--        <div class="gptslot" data-adunitid="1"></div>-->

<!--        <div class="gptslot" data-adunitid="2"></div>-->
        <!-- 广告上的第一行游戏 默认取前三个-->
<!--        <div class="one-game">-->
<!--          <div class="one-game-box">-->
<!--            <div-->
<!--              class="item"-->
<!--              v-for="(oneGItem, oneGIndex) in oneGameList"-->
<!--              :key="oneGIndex"-->
<!--            >-->
<!--              &lt;!&ndash; 跳转详情页 &ndash;&gt;-->
<!--              <a :href="`${origin}/detial.html?id=${oneGItem.id}`">-->
<!--                  <img :src="oneGItem.logo" alt="" />-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <!-- 广告 Google Ads -->
<!--         <component-google-ads :divId="homeDivId" :adCode="homeAdCode"></component-google-ads> -->
<!--        <component-google-ads-home></component-google-ads-home>-->
        <div class="wrapper" id='div-gpt-ad-1321715229427' style='min-width: 300px; min-height: 250px;'></div>
        <!-- 游戏主体部分 -->
        <component-game-body @goSelectedGameDetial="goSelectedGameDetial"></component-game-body>
<!--        <div class="gptslot" data-adunitid="2"></div>-->
        <!-- 底部没有更多数据提示 -->
        <div class="footer">
          <div>
            <p v-if="isShowNoMoreText && toShow" v-text="moreText"></p><!-- 数据加载完提示 -->
            <van-loading v-if="!isShowNoMoreText && toShow" type="spinner" color="#1989fa" /> <!--  下拉刷新Loading -->
          </div> 
        </div>

      </div>
      <!-- 底部条款及说明部分 -->
      <component-conditions></component-conditions>

    </div>

    <!-- 回到顶部按钮 -->
    <el-backtop :bottom="100">
      <div style="{height: 100%;width: 100%;background-color: #f2f5f6;box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);text-align: center;line-height: 40px;color: #1989fa;}">
        <i class="el-icon-arrow-up"></i>
      </div>
    </el-backtop>

  </div>
</template>
<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
<script>
import { getAllGameMenu, getPageGamesList, getQueryByChannel } from "@/api/index.js";
import { mapMutations } from "vuex";
import pagesTitle from "../components/component-pages-title.vue";
import gameBody from "../components/component-game-body.vue";
import conditions from "../components/component-terms-and-conditions.vue";
// import googleAds from "../components/component-google-ads.vue";
// import googleAdsHome from "../components/component-ads-home.vue";


export default {
  name: "gameHome",
  components: {
    "component-pages-title": pagesTitle,
    "component-game-body": gameBody,
    "component-conditions": conditions,
    // "component-google-ads": googleAds,
    // "component-google-ads-home": googleAdsHome,
  },
  data() {
    return {
      getPageGamesListData: {
        //获取分页游戏数据入参
        pageSize: 50,
        pageNum: 1,
        isEnable: 1,
        isDelete: 0
      },
      //接口补充需要的公共参数
      commonParams: {
        isEnable: 1,
        isDelete: 0
      },
      isShowMenu: true,//头部是否展示菜单按钮
      isInitGame: true,//是否初始化游戏数据
      sContH: 0,
      moreLenth: false,
      moreText: "",
      isShowNoMoreText: false,
      toShow: false,
      menuAllList: [], // 菜单数据
      gameAllList: [], // 游戏数据
      bodyGameList: [], // 主体页面游戏数据
      homeChannelAdsDate: {},// home页面广告数据
      homeDivId: '', // 首页广告dom id数据
      homeAdCode: '', // 首页广告 code
      origin: window.location.origin,
    };
  },
  computed: {
    // 第一行游戏，取排序后前三个
    // oneGameList() {
    //   let oneList = this.gameAllList.filter((item, index) => {
    //     return index < 3;
    //   });
    //   this.updateOneGameList(oneList);// 同步vuex
    //   return oneList;
    // },
  },
  created() {
    // todo 渠道ID测试数据，正式使用前要删掉
    localStorage.setItem('permission', '98639ba5e2a111eda05400163e032920');

    // 获取菜单数据
    getAllGameMenu(this.commonParams)
      .then((res) => {
        this.menuAllList = res.menus;
        this.updateMenuAllList(res.menus); //数据同步vuex
      })
      .catch((err) => {
        console.log(err);
      });

    // // 获取对应广告资源
    // getQueryByChannel(this.commonParams)
    //   .then(res => {
    //     if(res.code == 200) {
    //       this.getHomeDivId(res.data)
    //       // 获取对应广告数据
    //       res.data.forEach(item => {
    //           if(item.pageName == 'SY' && /anchor/.test(item.code)) {//首页锚点广告 anchor
    //             this.homeChannelAdsDate['indexAnchor'] = item;
    //
    //           } else if(item.pageName == 'SY' && /inter/.test(item.code)) {//首页穿插广告 inter
    //             this.homeChannelAdsDate['indexInter'] = item;
    //
    //           } else if(item.pageName == 'XQ' && /inter/.test(item.code)) {//详情页广告 inter
    //             this.homeChannelAdsDate['detial'] = item;
    //             localStorage.setItem('ChannelAdsDateXQ', JSON.stringify(item));
    //           }
    //       })
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
    
    // 获取分页游戏数据
    // 由于第一次请求页面数据较少，无法触发下拉加载更多数据，初始化页面设置定时器请求两次数据用于初始化页面
    let timer = setInterval(() => {
      if(this.isInitGame && this.getPageGamesListData.pageNum < 3) {
            this.getPageGamesListFn();
            this.getPageGamesListData.pageNum ++;
      } else {
        clearInterval(timer);
      }
    }, 100)
    
  },
  mounted() {
      googletag.cmd.push(function() { googletag.display('div-gpt-ad-1321715229427'); });
    googletag.cmd.push(() => {
      googletag.display(interstitialSlot);
    });
    googletag.cmd.push(() => {
      googletag.display(anchorSlot);
    });
    // 搜索栏筛选游戏
    this.$bus.$on("searchGameResetDataFn", (mes) => {
      this.searchGameResetDataFn(mes);
    });

    // 跳转游戏详情页
    this.$bus.$on("goSelectedGameDetialFn", (mes) => {
      this.goSelectedGameDetial(mes);
    });

    this.$nextTick(() => {
      console.log("进入下拉")
      let that = this;
      // 监听页面下拉动作，加载更多游戏
      window.addEventListener("scroll", () => {
        let sWrap = this.$refs.scrollWrap;
        // let sCont = this.$refs.scrollCont;

        let sWrapH = sWrap && sWrap.offsetHeight;
        // console.log('sWrap.offsetHeight ====>' + sWrap.offsetHeight)
        let sTop = window.pageYOffset;
        // console.log('window.pageYOffset ====>' + window.pageYOffset)
        if (this.sContH - (sTop + sWrapH) < 10) {
          this.toShow = true;
          this.getMoreGames();
        }
      });
    });
  },
  methods: {
    ...mapMutations(["updateMenuAllList", "updateGameAllList","updateOneGameList","updateBodyGameList"]),

    // 获取divId
    getHomeDivId(arr) {
      const currentType = 'SY'
      const typeId = '3'
      if (arr && Array.isArray(arr)) {
        const currentItem = arr.find(item => (item.pageName === currentType) && (item.typeId === typeId))
        if (currentItem) {
          this.homeDivId = currentItem.enumValue
          this.homeAdCode = currentItem.code
        }
      }
    },
    
    // 前往游戏详情页面
    goSelectedGameDetial(gameId) {
      // 首页和详情页要分两个html页面,改为跳转页面
      // location.href = '/detial.html?id=' + gameId;
      location.href = `${location.origin}/detial.html?id=${gameId}`
      // this.$router.push({
      //   name: 'gameDetial',
      //   query: {
      //     id: gameId
      //   }
      // })

      // this.$router.push('/detial?id=' + gameId);
    },

    // 组装页面主体部分展示所需游戏数据（4+2/4+2/...）
    setGameAllList(data) {
        // let arr = [], demo=[];
        let defaultList = data.filter((item, index) => {
            return index >= 3;
        });
      //
      //   let num = 0, nlength = defaultList.length;
      //   defaultList.forEach((ditem) => {
      //       if(nlength < 4) {//剩余，总长度小于4
      //         demo.push(ditem);
      //         nlength --;
      //         if(demo.length == 4) {
      //           arr.push(demo);
      //           demo = [];
      //         }
      //         if (nlength == 0) {
      //           arr.push(demo);
      //           demo = [];
      //         }
      //
      //       } else if(nlength == 4) {//剩余，总长度为4
      //         demo.push(ditem);
      //         nlength --;
      //
      //       } else if(nlength == 0) {//剩余，总长度为0
      //         arr.push(demo);
      //         demo = [];
      //
      //       } else {
      //         // 剩余，游戏数量还足够
      //         if(num < 4) {// 0,1,2,3
      //             demo.push(ditem);
      //             num ++;
      //             nlength --;
      //
      //         } else if(num%4==0) {//4
      //             arr.push(demo);
      //             demo = [];
      //             // this.$set(ditem, 'isToBigImg', true);
      //             demo.push(ditem);
      //             num ++;
      //             nlength --;
      //
      //         } else if(num < 6) {//5
      //             // this.$set(ditem, 'isToBigImg', true);
      //             demo.push(ditem);
      //             num ++;
      //             nlength --;
      //
      //         } else if(num%6==0) {//6
      //             arr.push(demo);
      //             demo = [];
      //             num = 0;
      //             demo.push(ditem);
      //             num ++;
      //             nlength --;
      //         }
      //
      //       }
      //
      //   });
      //
      //   // 设置图片放大属性
      //   arr.forEach(item => {
      //     // 每一条游戏数据，重置放大属性
      //     item.forEach(item2 => {
      //       this.$set(item2, 'isToBigImg', false);
      //     })
      //
      //     // 一行1个或2个的游戏，需要重新设置放大属性
      //     if(item.length <= 2) {
      //       item.forEach(item2 => {
      //         this.$set(item2, 'isToBigImg', true);
      //       })
      //     }
      //   })
      // console.log(arr);
      console.log(defaultList);
        this.bodyGameList = defaultList;
        this.updateBodyGameList(this.bodyGameList);// 同步vuex
    },

    // 重新获取游戏数据
    resetGetGameData() {
        // 由于第一次请求页面数据较少，无法触发下拉加载更多数据，初始化页面设置定时器请求两次数据用于初始化页面
        let timer = setInterval(() => {
          if(this.getPageGamesListData.pageNum < 3) {
                this.getPageGamesListFn();
                this.getPageGamesListData.pageNum ++;
          } else {
            clearInterval(timer);
          }
        }, 100)
    },

    /**
     * 菜单过滤，重置游戏数据
     * @param {} menuType 子组件传入过滤游戏类别参数
     */
    resetGameDataFn(menuType) {
      console.log('选择的菜单类别====>' + JSON.stringify(menuType));
      // 选择了菜单类别，需要清空原有数据，重新请求当前类别游戏数据
      if(menuType) {
        this.gameAllList = [];
        this.moreLenth = false;
        this.$refs.scrollCont = 0;
        //获取分页游戏数据入参
        this.getPageGamesListData = {
          pageSize: 50,
          pageNum: 1,
          isEnable: 1,
          isDelete: 0,
          typeId: menuType.typeId // 选择菜单，传给接口的过滤数据
        }
      }

      this.resetGetGameData();
    },

    /**
     * 关键字搜索过滤，重置游戏数据
     * @param {*} Prame 搜索游戏名字字段
     */
    searchGameResetDataFn(prame) {
      console.log('搜索的文案====>'+ JSON.stringify(prame));
      // 搜索框输入了关键字，筛选游戏
      if(prame) {
        this.gameAllList = [];
        this.moreLenth = false;
        this.$refs.scrollCont = 0;
        //获取分页游戏数据入参
        this.getPageGamesListData = {
          pageSize: 50,
          pageNum: 1,
          isEnable: 1,
          isDelete: 0,
          enName: prame // 搜索框根据关键字搜索，传给接口的过滤数据
        }
      }

      this.resetGetGameData();

    },

    //分页获取游戏数据 
    getPageGamesListFn() {
      console.log("toPages ====> " + this.getPageGamesListData.pageNum);
      // 获取分页游戏数据
      getPageGamesList(this.getPageGamesListData)
        .then((res) => {
          console.log("获取的分页数据====>");
          console.log(res);
          this.moreLenth = false;
          this.isInitGame = this.getPageGamesListData.pageNum >= 3 ? false : true;
          
          // 汇总分页游戏数据
          if (res.rows && res.rows.length > 0) {
            let gameListItem = res.rows.sort(function (a, b) {
              return a.order - b.order; // 游戏-按order 从小到大排序
            });

            //过滤掉重复的游戏数据
            this.gameAllList.forEach(item => {
              gameListItem = gameListItem.filter(item2 => {
                return item2.id != item.id
              })  
            })

            this.gameAllList = this.gameAllList.concat(gameListItem); // 合并游戏数据
            this.toShow = false;
            this.updateGameAllList(this.gameAllList); //数据同步vuex
            this.setGameAllList(this.gameAllList);
          }

          // 提示：没有更多数据
          // if((this.getPageGamesListData.pageSize -1) * this.getPageGamesListData.pageNum > res.total) {
          if(this.getPageGamesListData.pageSize * this.getPageGamesListData.pageNum > res.total) {
            this.moreLenth = true;
            this.moreText = "Can't give you any more games for now.";
            this.isShowNoMoreText = true;
            this.toShow = true;
          }
          
        })
        .catch((err) => {
          console.log(err);
        });

      this.$nextTick(() => {
        let sCont = this.$refs.scrollCont;
        this.sContH = sCont ? sCont.offsetHeight : 0;
        // console.log('this.sContH ===666===> ' + this.sContH);
      });
    },

    // 分页加载更多游戏
    getMoreGames() {
      if (this.moreLenth) {
        return;
      }

      console.log("加载更多...");
      this.moreLenth = true;
      this.getPageGamesListFn();
      this.getPageGamesListData.pageNum ++;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-main {
  padding: 0;
  overflow-y: auto;
  // 顶部
  .title {
    padding: 8px 15px;
    background: rgba(0, 229, 195, 0.85);
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    height: 45px;
    z-index: 999;
    //max-width: 375px;
    .pages-name {
      font-size: 24px;
    }
    .el-icon-position,
    .el-icon-s-unfold {
      font-size: 25px;
      cursor: pointer;
    }
    .title-box {
      text-align: center;
      position: relative;
      .el-icon-s-unfold {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
      .el-icon-position {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
  // 第一行游戏
  .one-game-box {
    padding: 15px 15px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
    .item {
      position: relative;
      width: 33%;
      margin: 0.26667rem 0;
      padding: 0 0.13333rem;
      img {
        width: 110px;
        height: 110px;
        border: 1px solid #ddd;
      }
      i {
        position: absolute;
        right: 0;
        bottom: 0;
        background: #bbb;
        cursor: pointer;
      }
    }
  }
  // 底部分页加载提示
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 14px;
    margin: 15px auto 30px;
    div {
      padding: 15px 0;
    }
  }
}
// 回到顶部
.el-backtop {
  background-color: #1caac5 !important;
  height: 40px;
  width: 40px;
  right: 20px !important;
  div {
    width: auto !important;
    background-color: #1caac5 !important;
    box-shadow: none !important;
    line-height: initial !important;
    color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 50%;
    [class*=" el-icon-"], [class^=el-icon-] {
      line-height: inherit !important;
    }
  }
}
</style>