<template>
  <div>
    <!-- 商城首页顶部标题 -->
    <div class="title">
      <div class="title-box">
        <!-- 默认公共 title -->
        <div class="common-title" v-if="!isShowSearchBox">
          <h3 class="pages-name">Game mall</h3>
          <!-- 菜单 -->
          <i
            v-if="isShowMenu"
            class="el-icon-s-unfold"
            @click="drawer = true"
          ></i>
          
          <!-- 返回首页 -->
          <van-icon
            v-if="!isShowMenu"
            class="van-apps"
            name="apps-o"
            @click="toIndex"
          />

          <!-- 分享 -->
          <!-- <i class="el-icon-position" @click="goShare"></i> -->

          <!-- 去掉分享，换为搜索功能 -->
          <i class="el-icon-search" @click="goSearch"></i>
        </div>

        <!-- 顶部搜索框 title-->
        <component-search-new v-if="isShowSearchBox"></component-search-new>
      </div>
    </div>

    <!-- 分类菜单选择 -->
    <el-drawer
      title="game menu"
      :visible.sync="drawer"
      :direction="direction"
      :size="'40%'"
      :show-close="false"
      class="menu"
    >
      <div class="menu-box">
        <div
          class="menu-item"
          v-for="(item, index) in menuAllListDate"
          :key="index"
        >
          <p @click="chooseMenu(item)">{{ item.enName }}</p>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapState, mapMutations } from "vuex";
import search from "../components/component-search-new.vue";

export default {
  name: "pagesTitle",
  props: ["isShowMenu"],
  components: {
    "component-search-new": search,
  },
  data() {
    return {
      drawer: false,
      direction: "ltr", //从左往右打开菜单
      shareLink: location.href, //分享页面链接
    };
  },
  computed: {
    ...mapState(["menuAllListDate", "isShowSearchBox"]),
  },
  methods: {
    ...mapMutations(["updateIsShowSearchBox"]),
    // 关键字搜索游戏
    goSearch() {
      this.updateIsShowSearchBox(true);
    },
    // 选择菜单
    chooseMenu(menu) {
      console.log("name=" + menu.enName + "---- typeId=" + menu.typeId);
      this.drawer = false;
      this.$emit("resetGameDataFn", { typeId: menu.typeId });
    },
    // 分享当前页面
    goShare() {
      console.log("开始复制分享链接......");
      var domUrl = document.createElement("input");
      domUrl.value = this.shareLink;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      // 提示复制成功
      Toast.success({
        message: "Copy Success!",
        className: "setToastWidth",
      });
    },
    // 回到首页
    toIndex() {
      // 首页和详情页要分两个html页面
      location.href = location.origin;

      // this.$router.push({
      //   name: "home",
      // });

      // this.$router.push('/home');
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding: 8px 15px;
  background: rgba(0, 229, 195, 0.85);
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: 45px;
  z-index: 999;
  //max-width: 375px;
  .pages-name {
    font-size: 24px;
  }
  .el-icon-position,
  .el-icon-search,
  .el-icon-s-unfold,
  .van-apps {
    font-size: 25px;
    cursor: pointer;
  }
  .title-box {
    text-align: center;
    position: relative;
    .el-icon-s-unfold,
    .van-apps {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
    .el-icon-position,
    .el-icon-search {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}
// 分类菜单选择栏
.menu-box {
  .menu-item {
    font-size: 20px;
    color: #fff;
    padding: 8px 10px;
    border-bottom: 1px dashed #999;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
  }
}
// menu ui 重写
:deep(.el-drawer) {
  background-color: rgba(0, 229, 195, 0.85);
  color: #fff;
}
:deep(.el-drawer__header) {
  background-color: rgba(0, 229, 195, 0.85);
  padding: 0px 20px;
  height: 50px;
  color: #fff;
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
:deep(.el-drawer__body) {
  color: #fff;
  background-color: rgba(0, 229, 195, 0.85);
}
</style>
